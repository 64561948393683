import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const RegisterPage = () => {
  // const initialValue={
  //   name: "",
  //   email: "",
  //   password: "",
  //   confirmPassword: "",
  // }
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  // console.log(formData,"gggg")
  const navigate=useNavigate()

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const handleSubmit = (e) => {
    e.preventDefault();
  
      const newErrors = {};
  
      if (!formData.name.trim()) newErrors.name = "Name is required.";
      if (!formData.email.trim()) {
        newErrors.email = "Email is required.";
      } else if (!/^[\w-.]+@[\w-]+\.[a-z]{2,}$/i.test(formData.email)) {
        newErrors.email = "Enter a valid email.";
      }
      if (!formData.password.trim()) newErrors.password = "Password is required.";
      if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = "Passwords do not match.";
      }
  
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        navigate('/login');
      }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#f5f5f5"
      padding={2}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        bgcolor="#fff"
        borderRadius={2}
        boxShadow={3}
        width={360}
        p={3}
        textAlign="center"
      >
        <Typography variant="h5" fontWeight="bold" mb={2} color="#333">
          Create an Account
        </Typography>

        <Box mb={2}>
          <TextField
            fullWidth
            label="Full Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            error={!!errors.name}
            helperText={errors.name}
          />
        </Box>

        <Box mb={2}>
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Box>

        <Box mb={2}>
          <TextField
            fullWidth
            label="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            error={!!errors.password}
            helperText={errors.password}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
        </Box>
    
        <Button
        // component={Link}
        // to="/login"
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            bgcolor: "#007bff",
            "&:hover": { bgcolor: "#0056b3" },
            fontWeight: "bold",
          }}
        >
          Register
        </Button>
      </Box>
    </Box>
  );
};

export default RegisterPage;
