/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

import headerlogo from './assets/img/Scr.png';
import heroimage from './assets/img/hero/6533310-removebg-preview.png';
import service_sec1 from './assets/img/bg/service_bg_1.png';
import boxicon1 from './assets/img/icon/service_card_1.svg';
import boxicon2 from './assets/img/icon/service_card_2.svg';
import boxicon3 from './assets/img/icon/service_card_3.svg';
import boxicon4 from './assets/img/icon/service_card_4.svg';
import img1 from './assets/img/normal/about_1_1.jpg';
import img2 from './assets/img/normal/about_1_2.jpg';
import img3 from './assets/img/normal/about_1_3.png';
import cta_bg_1 from './assets/img/bg/cta_bg_1.jpg';
import shape_1 from './assets/img/shape/shape_1.png';
import vector_1 from './assets/img/normal/vector_1.png';
import counter_1_1 from './assets/img/icon/counter_1_1.svg';
import counter_1_2 from './assets/img/icon/counter_1_2.svg';
import counter_1_3 from './assets/img/icon/counter_1_3.svg';
import counter_1_4 from './assets/img/icon/counter_1_4.svg';
import testi_bg_1 from './assets/img/bg/testi_bg_1.jpg';
import testi_1_1 from './assets/img/testimonial/testi_1_1.jpg';
import testi_1_2 from './assets/img/testimonial/testi_1_2.jpg';
import testi_1_3 from './assets/img/testimonial/testi_1_3.jpg';
import testi_1_4 from './assets/img/testimonial/testi_1_4.jpg';
import team_1_1 from './assets/img/team/team_1_1.jpg';
import team_1_2 from './assets/img/team/team_1_2.jpg';
import team_1_3 from './assets/img/team/team_1_3.jpg';
import team_1_4 from './assets/img/team/team_1_4.jpg';
import gallery_1_1 from './assets/img/gallery/gallery_1_1.jpg';
import gallery_1_2 from './assets/img/gallery/gallery_1_2.jpg';
import gallery_1_3 from './assets/img/gallery/gallery_1_3.jpg';
import gallery_1_4 from './assets/img/gallery/gallery_1_4.jpg';
import gallery_1_1jpg from './assets/img/gallery/gallery_1_1.jpg';
import gallery_1_2jpg from './assets/img/gallery/gallery_1_2.jpg';
import gallery_1_3jpg from './assets/img/gallery/gallery_1_3.jpg';
import gallery_1_4jpg from './assets/img/gallery/gallery_1_4.jpg';
import cta_bg_2jpg from './assets/img/bg/cta_bg_2.jpg';
import brand_1_1png from './assets/img/brand/brand_1_1.png';
import brand_1_2png from './assets/img/brand/brand_1_2.png';
import brand_1_3png from './assets/img/brand/brand_1_3.png';
import brand_1_4png from './assets/img/brand/brand_1_4.png';
import brand_1_5png from './assets/img/brand/brand_1_5.png';
import brand_1_6png from './assets/img/brand/brand_1_6.png';
import brand_1_7png from './assets/img/brand/brand_1_7.png';
import brand_1_8png from './assets/img/brand/brand_1_8.png';
import gallery11jpg from './assets/img/gallery/gallery_1_1.jpg';
import gallery12jpg from './assets/img/gallery/gallery_1_2.jpg';
import gallery13jpg from './assets/img/gallery/gallery_1_3.jpg';
import gallery14jpg from './assets/img/gallery/gallery_1_4.jpg';
import cta1png from './assets/img/normal/cta_1.png';
import blog21jpg from './assets/img/blog/blog_2_1.jpg';
import blog22jpg from './assets/img/blog/blog_2_2.jpg';
import footerbg1jpg from './assets/img/bg/footer_bg_1.jpg';
import post11 from './assets/img/blog/recent-post-1-1.jpg';
import post12 from './assets/img/blog/recent-post-1-2.jpg';
import { Link } from 'react-router-dom';
import SimpleSlider2 from './assets/js/courosel2';
import SimpleSlider3 from './assets/js/courosel3';
import SimpleSlider1 from './Slider1';
import LoginPage from './Login';
import RegisterPage from './Register';
function App() {
    return (
        <>
            <div className="App">
                <header className="fixed-top">


                    {/* <div className="ot-menu-wrapper">
        <div className="ot-menu-area text-center"><button className="ot-menu-toggle"><i className="fal fa-times"></i></button>
            <div className="mobile-logo"><a href="Index"><img src="assets/img/Maran.mp4" alt="Tronix" /></a></div>
            <div className="ot-mobile-menu">
                <ul>
                    <li><a href="Index">Home</a>
                    </li>
                    <li><a href="About">About Us</a></li>
                    <li><a href="#">Blog</a>
                    </li>
                    <li><a href="contact">Contact</a></li>
                </ul>
            </div>
        </div>
    </div> */}
                    {/* <header className="ot-header header-layout1">
        <div className="header-top">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
                    <div className="col-auto d-none d-lg-block">
                        <p className="header-notice">Mon-Fri: 8:00 AM - 6:30 PM</p>
                    </div>
                    <div className="col-auto">
                        <div className="header-links2">
                            <ul>
                                <li className="d-none d-sm-inline-block">
                                    <div className="links-menu">
                                        <ul>
                                            <li><a href="/Service">All Services</a></li>
                                            <li><a href="About">Careers</a></li>
                                            <li><a href="About">News</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li><i className="far fa-envelope"></i><a
                                        href="Info@kavimaran.com">Info@kavimaran.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="sticky-wrapper">
            <div className="menu-area">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-auto">
                            <div className="header-logo"><a href="Index"><img src={headerlogo}
                                        alt="Tronix/"/></a></div>
                        </div>
                        <div className="col-auto">
                            <nav className="main-menu d-none d-lg-inline-block">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/About">About Us</a></li>
                                    <li><a href="/Service">Service</a>
                                    </li>
                                    <li><a href="">Blog</a>
                                    </li>
                                    <li><a href="/Contact">Contact</a></li>
                                </ul>
                            </nav><button type="button" className="ot-menu-toggle d-block d-lg-none"><i
                                    className="far fa-bars"></i></button>
                        </div>
                        <div className="col-auto d-none d-xl-block">
                            <div className="header-button">
                                <div className="call-btn">
                                    <div className="icon-btn"><i className="fas fa-phone"></i></div>
                                    <div className="media-body"><span className="box-subtitle">Quick Call</span>
                                        <h4 className="box-title"><a href="tel:+9041236625">+91 7598984380</a></h4>
                                    </div>
                                </div><a href="contact" className="ot-btn btn-sm">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </header> */}
    <div className="ot-hero-wrapper hero-1" id="hero">
        <div className="hero-circle"></div>
        <div className="hero-shape1"></div>
        <div className="hero-inner">
            <div className="container">
                <div className="hero-style1"><span className="sub-title"><span className="text">Welcome To Maran Technologies</span></span>
                    <h1 className="hero-title"><span className="title1">Clear Thinking</span> <span className="title2">Makes
                            <span>Bright Future!</span></span></h1>
                    <p className="hero-text">The website design should be user-friendly, easy to navigate, and aesthetically
                        pleasing. It should be optimized for fast loading times, and the layout should be consistent
                        across all pages.</p>
                    <div className="btn-group"><a href="about.html" className="ot-btn">Discover More</a> <a
                            href="https://www.youtube.com/watch?v=_sI_Ps7JSEk" className="watch-btn popup-video">
                            <div className="play-btn"><i className="fas fa-play"></i></div><span className="text">Watch Video</span>
                        </a></div>
                </div>
            </div>
            <div className="hero-img"><img src={heroimage} /></div>
        </div>
    </div>

                    <section className="service-sec1" id="service-sec" data-bg-src={service_sec1}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-6 col-lg-7 col-md-8">
                                    <div className="title-area text-center"><span className="sub-title"><span className="text">Our
                                        services</span></span>
                                        <h2 className="sec-title">Service We Provide</h2>
                                        <p className="sec-text">We deliver innovative software solutions to meet diverse business needs, including custom development, web and mobile apps, cloud services, AI integration, and data analytics. Our goal is to simplify technology and empower businesses to thrive in a digital-first world.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row gy-4 justify-content-center">
                                <div className="col-xl-3 col-md-6">
                                    <div className="service-card">
                                        <div className="box-icon"><img src={boxicon1} alt="Icon" /></div>
                                        <h3 className="box-title"><a href="service-details.html">Custom Software Development</a></h3>
                                        <p className="box-text">Tailored software solutions to address specific business needs.</p><a
                                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="service-card">
                                        <div className="box-icon"><img src={boxicon2} alt="Icon" /></div>
                                        <h3 className="box-title"><a href="service-details.html">IT Management Service</a></h3>
                                        <p className="box-text">Developing a comprehense IT strategy that aligns.</p><a
                                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="service-card">
                                        <div className="box-icon"><img src={boxicon3} alt="Icon" /></div>
                                        <h3 className="box-title"><a href="service-details.html">Website & Mobile App Design</a></h3>
                                        <p className="box-text">Website and mobile apps design are critical.</p><a
                                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="service-card">
                                        <div className="box-icon"><img src={boxicon4} alt="Icon" /></div>
                                        <h3 className="box-title"><a href="service-details.html">Data Tracking Security</a></h3>
                                        <p className="box-text">Encryption is a method of converting sensitive.</p><a
                                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                                    </div>
                                </div>
                                <div class="btn-group"><a href="about.html" class="ot-btn">Discover More</a></div>
                            </div>
                        </div>
                    </section>
                    <div className="about-sec1" id="about-sec">
                        <div className="container space">
                            <div className="row align-items-center">
                                <div className="col-xl-5 col-lg-6">
                                    <div className="title-area mb-37"><span className="sub-title"><span className="text">Welcome To Our
                                        Company</span></span>
                                        <h2 className="sec-title">We Are Increasing Business Success With Technology</h2>
                                        <p className="sec-text">An IT solution service company may serve clients from various industries
                                            such as healthcare, finance, education, and manufacturing. They may work on a project basis,
                                            providing services for a specific project or on a long-term basis...</p>
                                    </div>
                                    <div className="dot-list">
                                        <ul>
                                            <li>Amazing communication.</li>
                                            <li>Best trending designing experience.</li>
                                            <li>Email & Live chat.</li>
                                        </ul>
                                    </div>
                                    <div className="mt-45"><a href="About" className="ot-btn">Discover More</a></div>
                                </div>
                                <div className="col-xl-7 col-lg-6">
                                    <div className="img-box1">
                                        <div className="img1"><img src={img1} alt="About" /></div>
                                        <div className="img2"><img src={img2} alt="Image" /></div>
                                        <div className="shape1 jump-reverse"><img src={img3} alt="Image" /></div>
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='backimg'>
                        <section className="space" data-bg-src={cta_bg_1}>

                            <div className="container">
                                <div className="row gy-30 align-items-center justify-content-center justify-content-md-between">
                                    <div className="col-md-auto">
                                        <div className="title-area mb-0 text-center text-md-start"><span className="sub-title text-white">We are
                                            here to answer your questions 24/7</span>
                                            <h2 className="sec-title text-white">Need A Consultation?</h2>
                                        </div>
                                    </div>
                                    <div className="col-md-auto text-center"><a href="shop-details.html" className="ot-btn style3">Let’s Get
                                        Started</a></div>
                                </div>
                            </div>

                        </section>
                    </div>

                    <div className="space">
                        <div className="shape-mockup spin" data-top="40%" data-left="4%"><img src={shape_1}
                            alt="shape" /></div>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-5 col-lg-6 text-center text-lg-start">
                                    <div className="pe-xxl-5">
                                        <div className="title-area"><span className="sub-title"><span className="text">Work With Us</span></span>
                                            <h2 className="sec-title">We Make Awesome IT Services For Your Newly Business</h2>
                                        </div>
                                        <div className="btn-group"><a href="contact" className="ot-btn">Start A Projects</a>
                                            <div className="call-text">
                                                <h4 className="box-title">Call Us: +91 7598984380</h4><span className="box-text">For any
                                                    question</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-7 col-lg-6">
                                    <div className="text-center mt-40 mt-lg-0"><img src={vector_1} alt="vector" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="container">
                            <div className="counter-card-wrap">
                                <div className="counter-card">
                                    <div className="box-icon"><img src={counter_1_1} alt="Icon" /></div>
                                    <div className="media-body">
                                        <h2 className="box-number"><span className="counter-number">44.8</span>K</h2><span className="box-text">JOBS
                                            COMPLETED</span>
                                    </div>
                                </div>
                                <div className="counter-card">
                                    <div className="box-icon"><img src={counter_1_2} alt="Icon" /></div>
                                    <div className="media-body">
                                        <h2 className="box-number"><span className="counter-number">325</span></h2><span className="box-text">MEDIA
                                            ACTIVITIES</span>
                                    </div>
                                </div>
                                <div className="counter-card">
                                    <div className="box-icon"><img src={counter_1_3} alt="Icon" /></div>
                                    <div className="media-body">
                                        <h2 className="box-number"><span className="counter-number">498</span></h2><span
                                            className="box-text">SKILLED EXPERTS</span>
                                    </div>
                                </div>
                                <div className="counter-card">
                                    <div className="box-icon"><img src={counter_1_4} alt="Icon" /></div>
                                    <div className="media-body">
                                        <h2 className="box-number"><span className="counter-number">42.8</span>K</h2><span
                                            className="box-text">HAPPY CLIENTS</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='testibg1'>
                        <section className="space" id="testi-sec" data-bg-src={testi_bg_1}>
                            <div className="container">
                                <div className="title-area"><span className="sub-title text-white"><span className="text">Client
                                    Testimonial</span></span>
                                    <h2 className="sec-title text-white">About Customer Stories</h2>
                                </div>
                                <div className="swiper ot-slider has-shadow" id="testiSlider1"
                                    data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"1"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"2"}}}'>
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="testi-card">
                                                <p className="box-text">“Customer review can help you measure customer satisfaction and identify
                                                    areas where your <u>IT solution service is meeting</u> or exceeding expectations. This
                                                    can help you build on those strengths and promote customer loyalty”</p>
                                                <div className="box-profile">
                                                    <div className="box-img"><img src={testi_1_1} alt="Avater" /></div>
                                                    <div className="media-body">
                                                        <h3 className="box-title">Edward Daniel</h3><span className="box-desig">Sales
                                                            Engineer</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="testi-card">
                                                <p className="box-text">“Always review can help you measure customer satisfaction and identify
                                                    areas where your IT solution service is meeting or <u>exceeding expectations</u>. This
                                                    can help you build on those strengths and promote customer loyalty”</p>
                                                <div className="box-profile">
                                                    <div className="box-img"><img src={testi_1_2} alt="Avater" /></div>
                                                    <div className="media-body">
                                                        <h3 className="box-title">Jussian Rose</h3><span className="box-desig">Cloud
                                                            Architect</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="testi-card">
                                                <p className="box-text">“Customer review can help you measure customer satisfaction and identify
                                                    areas where your <u>IT solution service is meeting</u> or exceeding expectations. This
                                                    can help you build on those strengths and promote customer loyalty”</p>
                                                <div className="box-profile">
                                                    <div className="box-img"><img src={testi_1_3} alt="Avater" /></div>
                                                    <div className="media-body">
                                                        <h3 className="box-title">Michael Matthew</h3><span className="box-desig">Azore
                                                            Expert</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="testi-card">
                                                <p className="box-text">“Positive review can help you measure customer satisfaction and identify
                                                    areas where your IT solution service is meeting or <u>exceeding expectations</u>. This
                                                    can help you build on those strengths and promote customer loyalty”</p>
                                                <div className="box-profile">
                                                    <div className="box-img"><img src={testi_1_4} alt="Avater" /></div>
                                                    <div className="media-body">
                                                        <h3 className="box-title">Justin Dacosta</h3><span className="box-desig">Cloud
                                                            Architect</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-pagination"></div>
                                </div>
                            </div>
                        </section>
                    </div> */}

                    <SimpleSlider1 />
                    

                    <SimpleSlider2 />
                    {/* <section className="space">
        <div className="container z-index-common">
            <div className="title-area text-center"><span className="sub-title"><span className="text">Team Members</span></span>
                <h2 className="sec-title">Our Top Skilled Experts</h2>
            </div>
            <div className="swiper ot-slider has-shadow" id="teamSlider1"
                data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"}}}'>
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team_1_1} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Rocky Morgan</a></h3><span
                                    className="box-desig">CEO Founder</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team_1_2} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Eliana Bellin</a></h3><span
                                    className="box-desig">Marketer</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team_1_3} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Emanuel Macu</a></h3><span
                                    className="box-desig">Designer</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team_1_4} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Donne Joanne</a></h3><span
                                    className="box-desig">Developer</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

                    <SimpleSlider3 />

                    {/* <div className="overflow-hidden space-bottom">
        <div className="container">
            <div className="row justify-content-lg-between justify-content-center align-items-end">
                <div className="col-lg">
                    <div className="title-area text-center text-lg-start"><span className="sub-title"><span className="text">Quick
                                Projects</span></span>
                        <h2 className="sec-title">Our Successful Projects</h2>
                    </div>
                </div>
                <div className="col-lg-auto d-none d-lg-block">
                    <div className="sec-btn">
                        <div className="icon-box"><button data-slider-prev="#gallerySlider1" className="slider-arrow default"><i
                                    className="far fa-arrow-left"></i></button> <button data-slider-next="#gallerySlider1"
                                className="slider-arrow default"><i className="far fa-arrow-right"></i></button></div>
                    </div>
                </div>
            </div>
        </div>
        <div className="">
            <div className="swiper ot-slider has-shadow" id="gallerySlider1"
                data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"},"1400":{"slidesPerView":"4"}}}'>
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="gallery-card">
                            <div className="box-img"><img src={gallery_1_1} alt="gallery image"/> <a
                                    href={ gallery11jpg} className="icon-btn popup-image"><i
                                        className="far fa-search"></i></a></div>
                            <div className="box-content">
                                <p className="box-subtitle">IT Solution</p>
                                <h3 className="box-title"><a href="project-details.html">Cross-Industry Expertise</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="gallery-card">
                            <div className="box-img"><img src={gallery_1_2} alt="gallery image"/> <a
                                    href={ gallery12jpg} className="icon-btn popup-image"><i
                                        className="far fa-search"></i></a></div>
                            <div className="box-content">
                                <p className="box-subtitle">IT Solution</p>
                                <h3 className="box-title"><a href="project-details.html">Business Solution</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="gallery-card">
                            <div className="box-img"><img src={gallery_1_3} alt="gallery image"/> <a
                                    href={ gallery13jpg} className="icon-btn popup-image"><i
                                        className="far fa-search"></i></a></div>
                            <div className="box-content">
                                <p className="box-subtitle">IT Solution</p>
                                <h3 className="box-title"><a href="project-details.html">Service Consoltation</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="gallery-card">
                            <div className="box-img"><img src={gallery_1_4} alt="gallery image"/> <a
                                    href={ gallery14jpg} className="icon-btn popup-image"><i
                                        className="far fa-search"></i></a></div>
                            <div className="box-content">
                                <p className="box-subtitle">IT Solution</p>
                                <h3 className="box-title"><a href="project-details.html">Website Security</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="gallery-card">
                            <div className="box-img"><img src={gallery_1_1jpg} alt="gallery image"/> <a
                                    href={gallery11jpg} className="icon-btn popup-image"><i
                                        className="far fa-search"></i></a></div>
                            <div className="box-content">
                                <p className="box-subtitle">IT Solution</p>
                                <h3 className="box-title"><a href="project-details.html">Cross-Industry Expertise</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="gallery-card">
                            <div className="box-img"><img src={gallery_1_2jpg} alt="gallery image"/> <a
                                    href={ gallery12jpg} className="icon-btn popup-image"><i
                                        className="far fa-search"></i></a></div>
                            <div className="box-content">
                                <p className="box-subtitle">IT Solution</p>
                                <h3 className="box-title"><a href="project-details.html">Business Solution</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="gallery-card">
                            <div className="box-img"><img src={gallery_1_3jpg} alt="gallery image"/> <a
                                    href={ gallery13jpg} className="icon-btn popup-image"><i
                                        className="far fa-search"></i></a></div>
                            <div className="box-content">
                                <p className="box-subtitle">IT Solution</p>
                                <h3 className="box-title"><a href="project-details.html">Service Consoltation</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="gallery-card">
                            <div className="box-img"><img src={gallery_1_4jpg} alt="gallery image"/> <a
                                    href={ gallery14jpg} className="icon-btn popup-image"><i
                                        className="far fa-search"></i></a></div>
                            <div className="box-content">
                                <p className="box-subtitle">IT Solution</p>
                                <h3 className="box-title"><a href="project-details.html">Website Security</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-block d-lg-none mt-40 text-center">
                <div className="icon-box"><button data-slider-prev="#gallerySlider1" className="slider-arrow default"><i
                            className="far fa-arrow-left"></i></button> <button data-slider-next="#gallerySlider1"
                        className="slider-arrow default"><i className="far fa-arrow-right"></i></button></div>
            </div>
        </div>
    </div> */}

                    <div className='ctabg2'>
                        <section className="cta-sec2" data-bg-src={cta_bg_2jpg}>
                            <div className="container">
                                <div className="row gy-30 align-items-center">
                                    <div className="col-lg-6 text-center text-lg-start">
                                        <div className="title-area mb-37"><span className="sub-title"><span className="text">Start Work With
                                            Us</span></span>
                                            <h2 className="sec-title">The Best <span className="text-theme">IT Service</span><br />Provider.</h2>
                                            <p className="sec-text">Managed IT services can help you outsource your IT needs to a third-party
                                                provider. This includes IT support, network monitoring, and maintenance, and disaster
                                                recovery and business continuity planning</p>
                                        </div><a href="About" className="ot-btn style4">Take Support</a>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="img-box2"><img src={cta1png} alt="cta" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="cta-texts"><span className="text">Fast 24/7 Customer Service</span>
                                <div className="line"></div><span className="text">Save time & valuable money</span>
                            </div>
                        </section>
                    </div>
                    <section className="overflow-hidden space" id="blog-sec">
                        <div className="container">
                            <div className="title-area text-center"><span className="sub-title"><span className="text">News Posts</span></span>
                                <h2 className="sec-title">Latest Blog Updates</h2>
                            </div>
                            <div className="row gx-44 gy-30">
                                <div className="col-lg-6">
                                    <div className="blog-box">
                                        <div className="blog-img"><img src={blog21jpg} alt="blog image" /></div>
                                        <div className="blog-content">
                                            <div className="blog-meta"><a href="blog.html"><i className="far fa-calendar"></i>15 Mar, 2023</a>
                                                <a className="author" href="blog.html"><i className="far fa-user"></i>By Tronix</a>
                                            </div>
                                            <h3 className="box-title"><a href="blog-details.html">Start building amazing tech business</a>
                                            </h3>
                                            <p className="box-text">As a small business owner, you know that having reliable IT solutions is
                                                essential for the success</p><a href="blog-details.html" className="ot-btn btn-sm">Read
                                                    More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="blog-box">
                                        <div className="blog-img"><img src={blog22jpg} alt="blog image" /></div>
                                        <div className="blog-content">
                                            <div className="blog-meta"><a href="blog.html"><i className="far fa-calendar"></i>16 Mar, 2023</a>
                                                <a className="author" href="blog.html"><i className="far fa-user"></i>By Tronix</a>
                                            </div>
                                            <h3 className="box-title"><a href="blog-details.html">Cloud computer allows you to access</a>
                                            </h3>
                                            <p className="box-text">Cybersecurity is a top concern for small businesses, as they are often
                                                more vulnerable to cyberattacks</p><a href="blog-details.html"
                                                    className="ot-btn btn-sm">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="space-bottom">
                        <div className="container">
                            <div className="title-area text-center"><span className="sub-title"><span className="text">Brand We Work
                                With</span></span>
                                <h2 className="sec-title">Trusted by thousands Business</h2>
                            </div>
                            <div className="brand-grid-wrap">
                                <div className="brand-grid"><img src={brand_1_1png} alt="Brand Logo" /></div>
                                <div className="brand-grid"><img src={brand_1_2png} alt="Brand Logo" /></div>
                                <div className="brand-grid"><img src={brand_1_3png} alt="Brand Logo" /></div>
                                <div className="brand-grid"><img src={brand_1_4png} alt="Brand Logo" /></div>
                                <div className="brand-grid"><img src={brand_1_5png} alt="Brand Logo" /></div>
                                <div className="brand-grid"><img src={brand_1_6png} alt="Brand Logo" /></div>
                                <div className="brand-grid"><img src={brand_1_7png} alt="Brand Logo" /></div>
                                <div className="brand-grid"><img src={brand_1_8png} alt="Brand Logo" /></div>
                            </div>
                        </div>
                    </div>
                    <div className='footerhomebg'>
                        <footer className="footer-wrapper footer-layout1" data-bg-src={footerbg1jpg}>
                            <div className="widget-area">
                                <div className="container">
                                    <div className="row justify-content-between">
                                        <div className="col-md-6 col-xl-auto">
                                            <div className="widget footer-widget">
                                                <h3 className="widget_title">About Us</h3>
                                                <div className="ot-widget-about">
                                                    <p className="footer-text">An IT consultancy can help you assess your technology needs and
                                                        develop a technology strategy that aligns with your business</p>
                                                    <div className="ot-social"><a href="https://www.facebook.com/"><i
                                                        className="fab fa-facebook-f"></i></a> <a href="https://www.twitter.com/"><i
                                                            className="fab fa-twitter"></i></a> <a href="https://www.linkedin.com/"><i
                                                                className="fab fa-linkedin-in"></i></a> <a href="https://www.whatsapp.com/"><i
                                                                    className="fab fa-whatsapp"></i></a> <a href="https://www.youtube.com/"><i
                                                                        className="fab fa-youtube"></i></a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-auto">
                                            <div className="widget widget_nav_menu footer-widget">
                                                <h3 className="widget_title">Links</h3>
                                                <div className="menu-all-pages-container">
                                                    <ul className="menu">
                                                        <li><a href="About">About Us</a></li>
                                                        <li><a href="About">Our Mission</a></li>
                                                        <li><a href="team.html">Meet The Teams</a></li>
                                                        <li><a href="project.html">Our Projects</a></li>
                                                        <li><a href="contact">Contact Us</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-auto">
                                            <div className="widget widget_nav_menu footer-widget">
                                                <h3 className="widget_title">Explore</h3>
                                                <div className="menu-all-pages-container">
                                                    <ul className="menu">
                                                        <li><a href="service.html">What We Offer</a></li>
                                                        <li><a href="About">Our Story</a></li>
                                                        <li><a href="blog.html">Latest Posts</a></li>
                                                        <li><a href="About">Help Center</a></li>
                                                        <li><a href="contact">Get Support</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xl-auto">
                                            <div className="widget footer-widget">
                                                <h3 className="widget_title">Recent Posts</h3>
                                                <div className="recent-post-wrap">
                                                    <div className="recent-post">
                                                        <div className="media-img"><a href="blog-details.html"><img
                                                            src={post11} alt="Blog Image" /></a></div>
                                                        <div className="media-body">
                                                            <h4 className="post-title"><a className="text-inherit" href="blog-details.html">Building
                                                                Renovation Tasks</a></h4>
                                                            <div className="recent-post-meta"><a href="blog.html"><i
                                                                className="far fa-calendar"></i>21 Jun, 2023</a></div>
                                                        </div>
                                                    </div>
                                                    <div className="recent-post">
                                                        <div className="media-img"><a href="blog-details.html"><img
                                                            src={post12} alt="Blog Image" /></a></div>
                                                        <div className="media-body">
                                                            <h4 className="post-title"><a className="text-inherit" href="blog-details.html">Get
                                                                Started With Our Team</a></h4>
                                                            <div className="recent-post-meta"><a href="blog.html"><i
                                                                className="far fa-calendar"></i>22 Jun, 2023</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-bottom">
                                <div className="container">
                                    <div className="footer-contact-wrap">
                                        <div className="footer-contact">
                                            <div className="box-icon icon-btn"><i className="fal fa-location-dot"></i></div>
                                            <div className="media-body">
                                                <h4 className="box-title">Location</h4>
                                                <p className="box-text">523 Vaithiyanathapuram Beach Road, Kottar Post - 629002</p>
                                            </div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="footer-contact">
                                            <div className="box-icon icon-btn"><i className="fal fa-clock"></i></div>
                                            <div className="media-body">
                                                <h4 className="box-title">Working Hours</h4>
                                                <p className="box-text"><span>Weekdays 9am - 5pm</span><br /><span>Weekend 10am - 4pm</span></p>
                                            </div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="footer-contact">
                                            <div className="box-icon icon-btn"><i className="fa-light fa-phone-volume"></i></div>
                                            <div className="media-body">
                                                <h4 className="box-title">Contact Us</h4>
                                                <p className="box-text"><span>Info@kavimaran.com</span><br /><span>+91 7598984380</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="copyright-wrap">
                                <div className="container text-center">
                                    <p className="copyright-text">Copyright <i className="fal fa-copyright"></i> 2023 <a
                                        href="Index">Maran Technologies</a>. All Rights Reserved.</p>
                                </div>
                            </div>
                        </footer>
                    </div>
                    <div className="scroll-top"><svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    </svg></div>
                    <script src="assets/js/vendor/jquery-3.7.1.min.js"></script>
                    <script src="assets/js/app.min.js"></script>
                    <script src="assets/js/main.js"></script>
                </header>
            </div>


        </>
    );
}

export default App;
