import React from "react"

import imglogosvg from './assets/img/logo.svg';
import shape2png from './assets/img/shape/shape_2.png';
import about41jpg from './assets/img/normal/about_4_1.jpg';
import counter11svg from './assets/img/icon/counter_1_1.svg';
import counter12svg from './assets/img/icon/counter_1_2.svg';
import counter13svg from './assets/img/icon/counter_1_3.svg';
import counter14svg from './assets/img/icon/counter_1_4.svg';
import team11jpg from './assets/img/team/team_1_1.jpg';
import team12jpg  from './assets/img/team/team_1_2.jpg';
import team13jpg  from './assets/img/team/team_1_3.jpg';
import team14jpg  from './assets/img/team/team_1_4.jpg';
import shape11png  from './assets/img/shape/shape_1.png';
import vector1png from './assets/img/normal/vector_1.png';
import testi24png  from './assets/img/testimonial/testi_2_4.png';
import testi25png from './assets/img/testimonial/testi_2_5.png';
import testi21jpg from './assets/img/testimonial/testi_2_1.jpg';
import testi22jpg from './assets/img/testimonial/testi_2_2.jpg';
import testi23jpg from './assets/img/testimonial/testi_2_3.jpg';
import brand11png from './assets/img/brand/brand_1_1.png';
import brand12png from './assets/img/brand/brand_1_2.png';
import brand13png from './assets/img/brand/brand_1_3.png';
import brand14png from './assets/img/brand/brand_1_4.png';
import brand15png from './assets/img/brand/brand_1_5.png';
import brand16png from './assets/img/brand/brand_1_6.png';
import brand17png from './assets/img/brand/brand_1_7.png';
import brand18png from './assets/img/brand/brand_1_8.png';
import post11jpg from './assets/img/blog/recent-post-1-1.jpg';
import post12jpg from './assets/img/blog/recent-post-1-2.jpg';

export default function About(){
    return <>
    {/* <div className="preloader"><button className="ot-btn preloaderCls style3">Cancel Preloader</button>
        <div className="preloader-inner"><span className="loader"></span></div>
    </div> */}
    <div className="ot-menu-wrapper">
        <div className="ot-menu-area text-center"><button className="ot-menu-toggle"><i className="fal fa-times"></i></button>
            <div className="mobile-logo"><a href="Index"><img src={imglogosvg} alt="Tronix"/></a></div>
            <div className="ot-mobile-menu">
                <ul>
                    <li className="menu-item-has-children"><a href="Index">Home</a>
                        <ul className="sub-menu">
                            <li><a href="Index">Home One</a></li>
                            <li><a href="index-2.html">Home Two</a></li>
                            <li><a href="index-3.html">Home Three</a></li>
                        </ul>
                    </li>
                    <li><a href="About">About Us</a></li>
                    <li className="menu-item-has-children"><a href="#">Service</a>
                        <ul className="sub-menu">
                            <li><a href="service.html">Service</a></li>
                            <li><a href="service-details.html">Service Details</a></li>
                        </ul>
                    </li>
                    <li className="menu-item-has-children"><a href="#">Pages</a>
                        <ul className="sub-menu">
                            <li><a href="team.html">Team</a></li>
                            <li><a href="team-details.html">Team Details</a></li>
                            <li><a href="pricing.html">Pricing Plans</a></li>
                            <li><a href="project.html">Project Gallery</a></li>
                            <li><a href="project-details.html">Project Details</a></li>
                        </ul>
                    </li>
                    {/* <li className="menu-item-has-children"><a href="#">Blog</a>
                        <ul className="sub-menu">
                            <li><a href="blog.html">Blog</a></li>
                            <li><a href="blog-grid.html">Blog Grid Style</a></li>
                            <li><a href="blog-details.html">Blog Details</a></li>
                        </ul>
                    </li> */}
                    <li><a href="contact">Contact</a></li>
                </ul>
            </div>
        </div>
    </div>
    {/* <header className="ot-header header-layout1">
        <div className="header-top">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
                    <div className="col-auto d-none d-lg-block">
                        <p className="header-notice">Mon-Fri: 8:00 AM - 6:30 PM</p>
                    </div>
                    <div className="col-auto">
                        <div className="header-links2">
                            <ul>
                                <li className="d-none d-sm-inline-block">
                                    <div className="links-menu">
                                        <ul>
                                            <li><a href="service.html">All Services</a></li>
                                            <li><a href="About">Careers</a></li>
                                            <li><a href="About">News</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li><i className="far fa-envelope"></i><a
                                        href="mailto:media@tronix.com">media@tronix.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="sticky-wrapper">
            <div className="menu-area">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-auto">
                            <div className="header-logo"><a href="Index"><img src="assets/img/logo.svg"
                                        alt="Tronix"/></a></div>
                        </div>
                        <div className="col-auto">
                            <nav className="main-menu d-none d-lg-inline-block">
                                <ul>
                                    <li className="menu-item-has-children"><a href="Index">Home</a>
                                        <ul className="sub-menu">
                                            <li><a href="Index">Home One</a></li>
                                            <li><a href="index-2.html">Home Two</a></li>
                                            <li><a href="index-3.html">Home Three</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="About">About Us</a></li>
                                    <li className="menu-item-has-children"><a href="#">Service</a>
                                        <ul className="sub-menu">
                                            <li><a href="service.html">Service</a></li>
                                            <li><a href="service-details.html">Service Details</a></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children"><a href="#">Pages</a>
                                        <ul className="sub-menu">
                                            <li><a href="team.html">Team</a></li>
                                            <li><a href="team-details.html">Team Details</a></li>
                                            <li><a href="pricing.html">Pricing Plans</a></li>
                                            <li><a href="project.html">Project Gallery</a></li>
                                            <li><a href="project-details.html">Project Details</a></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children"><a href="#">Blog</a>
                                        <ul className="sub-menu">
                                            <li><a href="blog.html">Blog</a></li>
                                            <li><a href="blog-grid.html">Blog Grid Style</a></li>
                                            <li><a href="blog-details.html">Blog Details</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="contact">Contact</a></li>
                                </ul>
                            </nav><button type="button" className="ot-menu-toggle d-block d-lg-none"><i
                                    className="far fa-bars"></i></button>
                        </div>
                        <div className="col-auto d-none d-xl-block">
                            <div className="header-button">
                                <div className="call-btn">
                                    <div className="icon-btn"><i className="fas fa-phone"></i></div>
                                    <div className="media-body"><span className="box-subtitle">Quick Call</span>
                                        <h4 className="box-title"><a href="tel:+9041236625">(904) 12-366-25</a></h4>
                                    </div>
                                </div><a href="contact" className="ot-btn btn-sm">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ,</header> */}
    <div className="breadcumbabout">
    <div className="breadcumb-wrapper" data-bg-src="assets/img/bg/breadcumb-bg.jpg">
        <div className="container">
            <div className="breadcumb-content">
                <h1 className="breadcumb-title">About Us</h1>
                <ul className="breadcumb-menu">
                    <li><a href="Index">Home</a></li>
                    <li>About Us</li>
                </ul>
            </div>
        </div>
    </div>
    </div>

    <div className="space" id="about-sec">
        <div className="shape-mockup jump d-none d-sm-block" data-bottom="20%" data-right="8%"><img
                src={shape2png} alt="shape"/></div>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 mb-35 mb-lg-0">
                    <div className="img-box6">
                        <div className="img1"><img src={about41jpg} alt="About"/></div>
                    </div>
                </div>
                <div className="col-xxl-6 col-lg-7 text-center text-lg-start">
                    <div className="ps-xl-5">
                        <div className="title-area mb-37"><span className="sub-title"><span className="text">More About
                                    Us</span></span>
                            <h2 className="sec-title">Quality & Experience IT Solution Company</h2>
                            <p className="sec-text">IT service ipsum dolor sit amet consectur adipiscing elit sed eiusmod ex
                                tempor incididunt labore dolore magna aliquaenim ad minim veniam quis nostrud
                                exercitation laboris.</p>
                        </div>
                        <div className="about-content-box">
                            <div className="about-media">
                                <div className="icon-btn"><i className="fas fa-check"></i></div>
                                <div className="media-body">
                                    <h3 className="box-title">The best agency solution 2023</h3>
                                    <p className="box-text">These tools will allow you to handle tasks such hanging sves
                                        assemble furniture</p>
                                </div>
                            </div>
                            <div className="btn-group"><a href="contact" className="ot-btn">Contact Us</a>
                                <div className="call-btn style2">
                                    <div className="box-icon"><i className="fas icon-btn fa-phone"></i></div>
                                    <div className="media-body"><span className="box-subtitle">Call Us 24/7</span>
                                        <h4 className="box-title"><a href="tel:+9041236625">(904) 12-366-25</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="">
        <div className="container">
            <div className="counter-card-wrap counter-sec-border">
                <div className="counter-card">
                    <div className="box-icon"><img src={counter11svg} alt="Icon"/></div>
                    <div className="media-body">
                        <h2 className="box-number"><span className="counter-number">44.8</span>K</h2><span className="box-text">JOBS
                            COMPLETED</span>
                    </div>
                </div>
                <div className="counter-card">
                    <div className="box-icon"><img src={counter12svg} alt="Icon"/></div>
                    <div className="media-body">
                        <h2 className="box-number"><span className="counter-number">325</span></h2><span className="box-text">MEDIA
                            ACTIVITIES</span>
                    </div>
                </div>
                <div className="counter-card">
                    <div className="box-icon"><img src={counter13svg} alt="Icon"/></div>
                    <div className="media-body">
                        <h2 className="box-number"><span className="counter-number">498</span></h2><span
                            className="box-text">SKILLED EXPERTS</span>
                    </div>
                </div>
                <div className="counter-card">
                    <div className="box-icon"><img src={counter14svg} alt="Icon"/></div>
                    <div className="media-body">
                        <h2 className="box-number"><span className="counter-number">42.8</span>K</h2><span
                            className="box-text">HAPPY CLIENTS</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section className="space">
        <div className="container z-index-common">
            <div className="title-area text-center"><span className="sub-title"><span className="text">Team Members</span></span>
                <h2 className="sec-title">Our Top Skilled Experts</h2>
            </div>
            <div className="swiper ot-slider has-shadow" id="teamSlider1"
                data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"}}}'>
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team11jpg} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Rocky Morgan</a></h3><span
                                    className="box-desig">CEO Founder</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team12jpg} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Eliana Bellin</a></h3><span
                                    className="box-desig">Marketer</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team13jpg} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Emanuel Macu</a></h3><span
                                    className="box-desig">Designer</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team14jpg} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Donne Joanne</a></h3><span
                                    className="box-desig">Developer</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="space-bottom">
        <div className="shape-mockup spin" data-top="40%" data-left="4%"><img src={shape11png}
                alt="shape"/></div>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-5 col-lg-6 text-center text-lg-start">
                    <div className="pe-xxl-5">
                        <div className="title-area"><span className="sub-title"><span className="text">Work With Us</span></span>
                            <h2 className="sec-title">We Make Awesome IT Services For Your Newly Business</h2>
                        </div>
                        <div className="btn-group"><a href="contact" className="ot-btn">Start A Projects</a>
                            <div className="call-text">
                                <h4 className="box-title">Call Us: 258-3258 235</h4><span className="box-text">For any
                                    question</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-7 col-lg-6">
                    <div className="text-center mt-40 mt-lg-0"><img src={vector1png} alt="vector"/></div>
                </div>
            </div>
        </div>
    </div>
    <div className="testibg2" >
    <section className="overflow-hidden space" id="testi-sec" data-bg-src="assets/img/bg/testi_bg_2.jpg">
        <div className="shape-mockup pulse" data-top="15%" data-left="2%">
            <div className="shape-circle circle1"></div>
        </div>
        <div className="shape-mockup pulse" data-bottom="12%" data-right="2%">
            <div className="shape-circle circle2"></div>
        </div>
        <div className="shape-mockup pulse d-none d-xl-block" data-top="45%" data-right="17%"><img
                src={testi24png} alt="image"/></div>
        <div className="shape-mockup pulse d-none d-md-block" data-top="15%" data-right="6%"><img
                src={testi25png} alt="image"/></div>
        <div className="container">
            <div className="title-area text-center"><span className="sub-title"><span className="text">Customer
                        Feedback</span></span>
                <h2 className="sec-title">People Talk About Us</h2>
            </div>
            <div className="testi-box-slide">
                <div className="swiper ot-slider" id="testiSlide2" data-slider-options='{"effect":"slide"}'>
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="testi-box">
                                <div className="box-img"><img src={testi21jpg} alt="Avater"/></div>
                                <h3 className="box-heading">“Elit penatibus curae aucto”</h3>
                                <p className="box-text">Sem a penatibus varius dui nostra vehicula gravida congue, potenti
                                    etiam erat justo faucibus fusce quis nulla eu, dignissim eget posuere blandit
                                    curabitur porta inceptos. Inceptos faucibus fringilla pharetra mi suscipit curabitu
                                </p>
                                <h4 className="box-title">Andrew Smith</h4>
                                <p className="box-desig">Designer at <a href="https://www.google.com/">(Montan_Agency)</a>
                                </p>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testi-box">
                                <div className="box-img"><img src={testi22jpg} alt="Avater"/></div>
                                <h3 className="box-heading">“Elit penatibus curae aucto”</h3>
                                <p className="box-text">Sem a penatibus varius dui nostra vehicula gravida congue, potenti
                                    etiam erat justo faucibus fusce quis nulla eu, dignissim eget posuere blandit
                                    curabitur porta inceptos. Inceptos faucibus fringilla pharetra mi suscipit curabitu
                                </p>
                                <h4 className="box-title">Alexan Miceli</h4>
                                <p className="box-desig">Developer at <a href="https://www.google.com/">(Atanu_Agency)</a>
                                </p>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testi-box">
                                <div className="box-img"><img src={testi23jpg} alt="Avater"/></div>
                                <h3 className="box-heading">“Elit penatibus curae aucto”</h3>
                                <p className="box-text">Sem a penatibus varius dui nostra vehicula gravida congue, potenti
                                    etiam erat justo faucibus fusce quis nulla eu, dignissim eget posuere blandit
                                    curabitur porta inceptos. Inceptos faucibus fringilla pharetra mi suscipit curabitu
                                </p>
                                <h4 className="box-title">Michael John</h4>
                                <p className="box-desig">Mnager at <a href="https://www.google.com/">(Shila_Tech)</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
    <div className="space">
        <div className="container">
            <div className="title-area text-center"><span className="sub-title"><span className="text">Brand We Work
                        With</span></span>
                <h2 className="sec-title">Trusted by thousands Business</h2>
            </div>
            <div className="brand-grid-wrap">
                <div className="brand-grid"><img src={brand11png} alt="Brand Logo"/></div>
                <div className="brand-grid"><img src={brand12png} alt="Brand Logo"/></div>
                <div className="brand-grid"><img src={brand13png} alt="Brand Logo"/></div>
                <div className="brand-grid"><img src={brand14png} alt="Brand Logo"/></div>
                <div className="brand-grid"><img src={brand15png} alt="Brand Logo"/></div>
                <div className="brand-grid"><img src={brand16png} alt="Brand Logo"/></div>
                <div className="brand-grid"><img src={brand17png} alt="Brand Logo"/></div>
                <div className="brand-grid"><img src={brand18png} alt="Brand Logo"/></div>
            </div>
        </div>
    </div>

    <div className="footerabout">
    <footer className="footer-wrapper footer-layout1" data-bg-src="assets/img/bg/footer_bg_1.jpg">
        <div className="widget-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-6 col-xl-auto">
                        <div className="widget footer-widget">
                            <h3 className="widget_title">About Us</h3>
                            <div className="ot-widget-about">
                                <p className="footer-text">An IT consultancy can help you assess your technology needs and
                                    develop a technology strategy that aligns with your business</p>
                                <div className="ot-social"><a href="https://www.facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a href="https://www.twitter.com/"><i
                                            className="fab fa-twitter"></i></a> <a href="https://www.linkedin.com/"><i
                                            className="fab fa-linkedin-in"></i></a> <a href="https://www.whatsapp.com/"><i
                                            className="fab fa-whatsapp"></i></a> <a href="https://www.youtube.com/"><i
                                            className="fab fa-youtube"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-auto">
                        <div className="widget widget_nav_menu footer-widget">
                            <h3 className="widget_title">Links</h3>
                            <div className="menu-all-pages-container">
                                <ul className="menu">
                                    <li><a href="About">About Us</a></li>
                                    <li><a href="About">Our Mission</a></li>
                                    <li><a href="team.html">Meet The Teams</a></li>
                                    <li><a href="project.html">Our Projects</a></li>
                                    <li><a href="contact">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-auto">
                        <div className="widget widget_nav_menu footer-widget">
                            <h3 className="widget_title">Explore</h3>
                            <div className="menu-all-pages-container">
                                <ul className="menu">
                                    <li><a href="service.html">What We Offer</a></li>
                                    <li><a href="About">Our Story</a></li>
                                    <li><a href="blog.html">Latest Posts</a></li>
                                    <li><a href="About">Help Center</a></li>
                                    <li><a href="contact">Get Support</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-auto">
                        <div className="widget footer-widget">
                            <h3 className="widget_title">Recent Posts</h3>
                            <div className="recent-post-wrap">
                                <div className="recent-post">
                                    <div className="media-img"><a href="blog-details.html"><img
                                                src={post11jpg} alt="Blog Image"/></a></div>
                                    <div className="media-body">
                                        <h4 className="post-title"><a className="text-inherit" href="blog-details.html">Building
                                                Renovation Tasks</a></h4>
                                        <div className="recent-post-meta"><a href="blog.html"><i
                                                    className="far fa-calendar"></i>21 Jun, 2023</a></div>
                                    </div>
                                </div>
                                <div className="recent-post">
                                    <div className="media-img"><a href="blog-details.html"><img
                                                src={post12jpg} alt="Blog Image"/></a></div>
                                    <div className="media-body">
                                        <h4 className="post-title"><a className="text-inherit" href="blog-details.html">Get
                                                Started With Our Team</a></h4>
                                        <div className="recent-post-meta"><a href="blog.html"><i
                                                    className="far fa-calendar"></i>22 Jun, 2023</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="footer-contact-wrap">
                    <div className="footer-contact">
                        <div className="box-icon icon-btn"><i className="fal fa-location-dot"></i></div>
                        <div className="media-body">
                            <h4 className="box-title">Location</h4>
                            <p className="box-text">258 Daniel Mansion 258 Berlin Germany</p>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="footer-contact">
                        <div className="box-icon icon-btn"><i className="fal fa-clock"></i></div>
                        <div className="media-body">
                            <h4 className="box-title">Working Hours</h4>
                            <p className="box-text"><span>Weekdays 8am - 22pm</span><br/><span>Weekend 10am - 12pm</span></p>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="footer-contact">
                        <div className="box-icon icon-btn"><i className="fa-light fa-phone-volume"></i></div>
                        <div className="media-body">
                            <h4 className="box-title">Contact Us</h4>
                            <p className="box-text"><span>info@tronix.com</span><br/><span>(+259) 2257 6156</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-wrap">
            <div className="container text-center">
                <p className="copyright-text">Copyright <i className="fal fa-copyright"></i> 2023 <a
                        href="Index">Tronix</a>. All Rights Reserved.</p>
            </div>
        </div>
    </footer>
    </div>
    <div className="scroll-top"><svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
               style={{
                transition: "stroke-dashoffset 10ms linear 0s",
                strokeDasharray: "307.919, 307.919",
                strokeDashoffset: 307.919
              }}
              >
            </path>
        </svg></div>
    <script src="assets/js/vendor/jquery-3.7.1.min.js"></script>
    <script src="assets/js/app.min.js"></script>
    <script src="assets/js/main.js"></script>
</>
}



