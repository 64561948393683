import React from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testi_bg_1 from '../src/assets/img/bg/testi_bg_1.jpg';
import testi_1_1 from '../src/assets/img/testimonial/testi_1_1.jpg';
import testi_1_2 from '../src/assets/img/testimonial/testi_1_2.jpg';
import testi_1_3 from '../src/assets/img/testimonial/testi_1_3.jpg';
import testi_1_4 from '../src/assets/img/testimonial/testi_1_4.jpg';
function SimpleSlider1() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <>
      <div className='testibg1'>
    <section className="space" id="testi-sec" data-bg-src={testi_bg_1}>
        <div className="container">
            <div className="title-area"><span className="sub-title text-white"><span className="text">Client
                        Testimonial</span></span>
                <h2 className="sec-title text-white">About Customer Stories</h2>
            </div>
            <div className="swiper ot-slider has-shadow" id="testiSlider1"
                data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"1"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"2"}}}'>
                <div className="swiper-wrapper">
    <Box
    
      sx={{
        maxWidth: "1000px",
        margin: "0 auto",
        // padding: "20px",
        backgroundImage: "url()",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Slider {...settings}>
        {/* Slide 1 */}
        <Box
        
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
            // background: "linear-gradient(to right, #3f51b5, #5c6bc0)",
            borderRadius: "8px",
            color: "#fff",
            textAlign: "center",
            padding: "20px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box>
          <div className="swiper-slide">
                        <div className="testi-card">
                            <p className="box-text">“Customer review can help you measure customer satisfaction and identify
                                areas where your <u>IT solution service is meeting</u> or exceeding expectations. This
                                can help you build on those strengths and promote customer loyalty”</p>
                            <div className="box-profile">
                                <div className="box-img"><img src={testi_1_1} alt="Avater"/></div>
                                <div className="media-body">
                                    <h3 className="box-title">Edward Daniel</h3><span className="box-desig">Sales
                                        Engineer</span>
                                </div>
                            </div>
                        </div>
                    </div>
          </Box>
        </Box>

        {/* Slide 2 */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
            background: "linear-gradient(to right, #3f51b5, #5c6bc0)",
            borderRadius: "8px",
            color: "#fff",
            textAlign: "center",
            padding: "20px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box>
            
            <Typography variant="h4" sx={{ fontWeight: 700, marginBottom: "10px" }}>
              On-Time Delivery
            </Typography>
            <Typography variant="body1">
              Never miss a deadline with our fast and reliable essay writing service.
            </Typography>
          </Box>
        </Box> */}

        {/* Slide 3 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
            // background: "linear-gradient(to right, #3f51b5, #5c6bc0)",
            borderRadius: "8px",
            color: "#fff",
            textAlign: "center",
            padding: "20px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box>
          <div className="swiper-slide">
                        <div className="testi-card">
                            <p className="box-text">“Always review can help you measure customer satisfaction and identify
                                areas where your IT solution service is meeting or <u>exceeding expectations</u>. This
                                can help you build on those strengths and promote customer loyalty”</p>
                            <div className="box-profile">
                                <div className="box-img"><img src={testi_1_2} alt="Avater"/></div>
                                <div className="media-body">
                                    <h3 className="box-title">Jussian Rose</h3><span className="box-desig">Cloud
                                        Architect</span>
                                </div>
                            </div>
                        </div>
                    </div>
          </Box>
        </Box>

        {/* Slide 4 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
            // background: "linear-gradient(to right, #3f51b5, #5c6bc0)",
            borderRadius: "8px",
            color: "#fff",
            textAlign: "center",
            padding: "20px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box>
          <div className="swiper-slide">
                        <div className="testi-card">
                            <p className="box-text">“Customer review can help you measure customer satisfaction and identify
                                areas where your <u>IT solution service is meeting</u> or exceeding expectations. This
                                can help you build on those strengths and promote customer loyalty”</p>
                            <div className="box-profile">
                                <div className="box-img"><img src={testi_1_3} alt="Avater"/></div>
                                <div className="media-body">
                                    <h3 className="box-title">Michael Matthew</h3><span className="box-desig">Azore
                                        Expert</span>
                                </div>
                            </div>
                        </div>
                    </div>
          </Box>
        </Box>

        {/* Slide 5 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
            // background: "linear-gradient(to right, #3f51b5, #5c6bc0)",
            borderRadius: "8px",
            color: "#fff",
            textAlign: "center",
            padding: "20px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box>
          <div className="swiper-slide">
                        <div className="testi-card">
                            <p className="box-text">“Positive review can help you measure customer satisfaction and identify
                                areas where your IT solution service is meeting or <u>exceeding expectations</u>. This
                                can help you build on those strengths and promote customer loyalty”</p>
                            <div className="box-profile">
                                <div className="box-img"><img src={testi_1_4} alt="Avater"/></div>
                                <div className="media-body">
                                    <h3 className="box-title">Justin Dacosta</h3><span className="box-desig">Cloud
                                        Architect</span>
                                </div>
                            </div>
                        </div>
                    </div>
          </Box>
        </Box>
      </Slider>
    </Box>
    </div>
    </div>
    </div>
    </section>
    </div>
    

    </>
  );
}

export default SimpleSlider1;