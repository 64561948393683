import React from "react"
import LoginPage from "./Login"
import RegisterPage from "./Register"

export default function Admin(){
    return <>
    <RegisterPage />
     <LoginPage />
    </>}

   